@import "../variables.modules";

nav {
  transition: $transition;
}

.active {
  border-bottom: 7px solid;
  border-image-slice: 1;
}

.largeFont {
  font-size: 2rem;
  cursor: pointer;
  transition: font-size 0.3s ease; /* Fügt eine Übergangsanimation hinzu */
}

.floatingText {
  animation: float 2s ease infinite;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

li {
  transition: $transition;

  &:hover {
    transform: translateY(-3px);
    transition: $transition;
  }
}

.link:hover {
  color: unset;
}
