$purple: #8D53FF;
$pink: #CA6BE6;
$white: #fffefe;
$dark: #110505;
$red: #FF6057;
$yellow: #FFBD2E;
$green: #27C93F;
$lightgray: #9467e8;


$transition: all 250ms ease;

.shadowed {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,0.4);
}